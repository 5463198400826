:where(.css-dev-only-do-not-override-1ez6nz9).ant-collapse>.ant-collapse-item >.ant-collapse-header {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: 12px 16px;
    color: rgba(0, 0, 0, 0.88);
    line-height: 1.5714285714285714;
    cursor: pointer;
    transition: all 0.3s,visibility 0s;
}



.ant-picker-panel-container{
    // background-color: red !important;
    margin-left: 0px !important;
}