.forms{
    >h1{
      font-size: 24px;
      font-weight: 700;
      line-height: 24px;
      text-align: left;
      
    }
    >div>h2{
      font-size: 14px;
      font-weight: 700;
      line-height: 24px;
      text-align: left;
    }
    .line{
      width: 100%;
      height: 1.5px;
      background-color: rgba(209, 209, 209, 1);
      margin-bottom: 40px;
    }
  }
  .ant-form-item .ant-form-item-label{
    text-align: left;
  }
  .form-card {
    width: 98%;
    height: 100px;
    text-align: center;
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    align-items: center;
    background-color: #ffff;
    box-shadow: 0px 0px 10px rgba(225, 225, 225, 0.7);
    @media only screen and (max-width: 750px) {
      height: 60px;
    }
  
    &__title {
      width: 45%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      // align-items: center;
      padding-left: 5px;
      padding-right: 4px;
      @media only screen and (max-width: 991px) {
        width: 40%;
        padding-left: 10px;
      }
      > h5 {
        font-size: 16px;
        font-weight: bold;
        line-height: 22px;
        @media only screen and (max-width: 750px) {
          font-size: 14px;
          line-height: 18px;
        }
      }
      > h6 {
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;
        color: #18324e;
        @media only screen and (max-width: 750px) {
          font-size: 11px;
          line-height: 14px;
        }
      }
    }
    &__divider {
      height: 110px;
      width: 1px;
      margin-left: 3px;
      background-color: rgba(225, 225, 225, 1);
      @media only screen and (max-width: 750px) {
        height: 50px;
      }
    }
    &__desc {
      display: flex;
      flex-direction: column;
      width: 40%;
      padding-left: 6%;
      padding-right: 1%;
      font-size: 16;
      line-height: 20px;
      @media only screen and (max-width: 991px) {
        padding-left: 6%;
        font-size: 12px;
        @media only screen and (max-width: 550px) {
          padding-left: 4%;
          font-size: 10px;
        }
      }
    }
    & > a > button {
      border: none;
      border-radius: 4px;
      width: 100%;
      height: 100%;
      background-color: rgba(24, 50, 78, 1);
      color: #ffff;
      font-size: 16px;
      text-align: center !important;
      margin-right: 2%;
      margin-left: 1%;
      @media only screen and (max-width: 750px) {
        font-size: 10px;
      }
    }
    > a {
      color: #ffff;
      text-decoration: none;
      width: 20%;
      height: 40%;
    }
    &__button {
      border: none;
      border-radius: 4px;
      width: 11%;
      height: 20%;
      background-color: rgba(24, 50, 78, 1);
      color: #ffff;
      font-size: 16px;
      text-align: center;
      margin-right: 2%;
      margin-left: 1%;
      @media only screen and (max-width: 750px) {
        font-size: 10px;
      }
    }
  }
  .detile{
    align-items: center;
    padding-left: 3rem;
    padding-right: 3rem;
    @media only screen and (max-width: 991px) {
      padding-left: 0;
      padding-right: 0;
    }
    >div{
      justify-content: center;
      display: flex;
      >table{
      width: 100%;
      th{
        background-color: rgba(247, 247, 247, 1);
        text-align: center;
        padding: 10px;
        font-weight: 700;
        font-size: 18px;
      }
      td{
        background-color: #FCFCFC;
        border-bottom: 1px solid rgba(242, 242, 242, 1);
        padding: 10px;
      }
  
    }}
  }
  .personalTable{
    td{
      font-weight: 700;
      
      >h6{
        font-weight: 400;
      }
    }
    
  }
  .name-table:hover{
    text-decoration: underline;
    cursor: pointer;
    }
    
  