:focus:not(:focus-visible) {
  outline: none;
}
.btn-reset,
.ctrl-item,
.dropdown .list button {
  padding: 0;
  border: 0;
  outline: 0;
  background: none;
}
.containerOfCrop {
  box-sizing: border-box;
  position: relative;
  width: 400px;
  margin: 20px auto;

}
.app-title {
  position: absolute;
  text-align: center;
  color: #ccc;
  font-size: 40px;
  left: 0;
  right: 0;
  top: -135px;
}
.player {
  position: relative;
  margin: 0 auto;
  height: 160px;
  display: block;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}
.player .wave-canvas {
  position: absolute;
}
.player-landing {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #ddd;
  font-size: 24px;
}
.dragger {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  background: #999;
  cursor: col-resize;
}
.dragger::after {
  content: '';
  position: absolute;
  left: -2px;
  right: -2px;
  top: 0;
  bottom: 0;
}
.dragger:hover {
  background: #333;
}
.drag-current {
  background: #0cf;
}
.landing {
  padding-bottom: 100px;
}
.landing h2 {
  padding: 0;
  margin: 0;
  text-align: center;
  color: #ccc;
  font-size: 40px;
  margin-bottom: 40px;
}
.controllers {
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
}
.seconds {
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 36px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  gap: 2px;
}
.file input {
  visibility: hidden;
  position: absolute;
 
}
.file{
  width: 100%;
}
.file-main {
  width: 100%;
  display: block;
  margin: 0 auto;
  padding: 40px;
  text-align: center;
  cursor: pointer;
  color: #999;
  transition: 0.3s;
  background: none;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
.file-main .icon {
  margin: -1px 5px 0;
  font-size: 24px;
}
.file-main:hover {
  color: #6ac;
  border-color: #6ac;
}
.icon {
  width: 1em;
  height: 1em;
  fill: currentColor;
  vertical-align: middle;
}
.file.ctrl-item{
  width: max-content;
}
@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.icon.__icon-spin {
  animation: spin infinite 1s linear;
}
.ctrl-item {
  display: inline-block;
  font-size: 16px;
  text-align: center;
  color: #999;
  padding: 10px;
  margin-right: 10px;
  /* box-shadow: 0 0 15px rgba(0, 0, 0, 0.1); */
  cursor: pointer;
}
.ctrl-item:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);
}
.ctrl-item .icon {
  display: block;
}
.dropdown {
  display: inline-block;
  position: relative;
}
.dropdown .list-wrap {
  position: relative;
}
.dropdown .list {
  position: absolute;
  width: 60px;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  transition-duration: 0.3s;
  transition-property: opacity, visibility;
  list-style: none;
  background: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  padding: 0;
  margin: 0;
  z-index: 10;
}
.dropdown .list button {
  display: block;
  width: 100%;
  text-align: center;
  padding: 5px 0;
  color: inherit;
  font-size: 12px;
  cursor: pointer;
}
.dropdown .list button:hover {
  background: #333;
  color: #fff;
}
.dropdown:hover .list {
  opacity: 1;
  visibility: visible;
}
.clipper {
  position: absolute;
  width: 400px;
  height: 100%;
}
.cursor-current {
  position: absolute;
  font-size: 12px;
  top: -22px;
  padding: 1px 3px;
  text-align: center;
  color: #fff;
  transform: translate(-50%) scale(0.8);
  background: #0cf;
}
.cursor-current .num {
  font-family: monospace;
}
.cursor-current::after {
  content: '';
  position: absolute;
  border: 5px solid transparent;
  border-top-color: #0cf;
  bottom: -9px;
  left: 50%;
  margin-left: -5px;
}
