.ant-tabs-tab {
  color: red;
  // color: #226bda !important;
}
.ant-tabs-content-holder {
  background-color: white;
}
.ant-tabs-tab :hover {
  > div > span > span {
    color: black !important;
  }
}
.ant-tabs-tab-active {
  color: black;
  background-color: white !important;
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
  @media only screen and (max-width: 991px) {
    border-bottom-left-radius: 8px;
    border-top-right-radius: 8px;
    // height: 100px;
    // min-height: 400px !important;
    // overflow: scroll;
  }
}

.ant-tabs .ant-tabs-tab {
  @media only screen and (max-width: 991px) {
    margin: 0 0 0 0;
    padding: 0 15px;
    max-height: 60px;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    justify-content: center;
  }
}
.ant-tabs .ant-tabs-tab + .ant-tabs-tab {
  @media only screen and (max-width: 991px) {
    margin: 0 0 0 0;
  }
}

.ant-tabs .ant-tabs-tab:hover {
  color: black;
  background-color: white;
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;

  > div > span > span {
    color: black !important;
  }
  > div > span > svg {
    color: black !important;
  }
  > div > span > span > span > div {
    color: black !important;
  }

  @media only screen and (max-width: 991px) {
    border-radius: 8px;
  }
}
:where(.css-dev-only-do-not-override-1ez6nz9).ant-tabs
  .ant-tabs-content-holder {
  min-height: 100vh;
  @media only screen and (max-width: 991px) {
    max-width: 100vw;
  }
}
.ant-tabs-top > .ant-tabs-nav {
  @media only screen and (max-width: 991px) {
    margin-bottom: 0;
  }
}
:where(.css-dev-only-do-not-override-1ez6nz9).ant-tabs-left
  > .ant-tabs-content-holder
  > .ant-tabs-content
  > .ant-tabs-tabpane,
:where(.css-dev-only-do-not-override-1ez6nz9).ant-tabs-left
  > div
  > .ant-tabs-content-holder
  > .ant-tabs-content
  > .ant-tabs-tabpane {
  padding-left: 0 !important;
}
:where(.css-dev-only-do-not-override-gnqja7).ant-tabs-left
  > .ant-tabs-content-holder
  > .ant-tabs-content
  > .ant-tabs-tabpane,
:where(.css-dev-only-do-not-override-gnqja7).ant-tabs-left
  > div
  > .ant-tabs-content-holder
  > .ant-tabs-content
  > .ant-tabs-tabpane {
  padding-left: 0 !important;
}

.ant-tabs-tab-btn {
  > span > section {
    display: flex;
    justify-content: left;
  }
}
.dashboardInfo {
  width: 25%;
  height: 80px;
  background: linear-gradient(180deg, #676df2 0%, #226bda 100%);
  color: white;
  font-size: 20px;
  font-weight: 700;
  box-shadow: 0px 4px 10px 0px rgba(33, 37, 53, 0.4);
  padding: 10px;
  text-align: center;
}
.anticon-close {
  color: white;
}
.nav-mobile-btn {
  margin-top: 10px;
  width: 40px;
  height: 40px;
  border: none;
  background-color: #2a2a2a;
  color: white;
}
.nav-mobile-btn-active {
  margin-top: 10px;
  width: 40px;
  height: 40px;
  border: none;
  background-color: white;
  border-radius: 8px;
  color: #2a2a2a;
}
:where(.css-dev-only-do-not-override-1ez6nz9).ant-drawer-left
  > .ant-drawer-content-wrapper {
  box-shadow: none;
}
:where(.css-1ez6nz9).ant-tabs-left
  > .ant-tabs-content-holder
  > .ant-tabs-content
  > .ant-tabs-tabpane,
:where(.css-1ez6nz9).ant-tabs-left
  > div
  > .ant-tabs-content-holder
  > .ant-tabs-content
  > .ant-tabs-tabpane {
  padding-left: 0px !important;
}
:where(.css-gnqja7).ant-tabs-left
  > .ant-tabs-content-holder
  > .ant-tabs-content
  > .ant-tabs-tabpane {
  padding-left: 0px !important;
}

.hr {
  margin: 0.5rem 0;
}

.span {
  width: 100%;
  height: 100%;
}
.span:hover > span {
  color: black !important;
}
.span2:hover > span {
  color: black;
}

.send-email-button {
  position: fixed;
  right: 8px;
  top: 50%;
  height: 140px;
  transform: translateY(50%);
  background-color: #2e6cdf;
  color: white;
  padding: 9px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  z-index: 1000;
  writing-mode: vertical-rl;
  transform-origin: center;
  rotate: 180deg;
  text-decoration: none;
  @media only screen and (max-width: 991px) {
    right: 0px;
    padding: 7px;
    font-size: 12px;
    height: 100px;
  }
}

.send-email-button:hover {
  background-color: #0056b3;
  color: white;
}
