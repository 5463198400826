.ant-card .ant-card-body {
  padding:0 10px 0 10px;
}

.ant-dropdown .ant-dropdown-menu, .ant-dropdown-menu-submenu .ant-dropdown-menu{
    border-color: grey
}
.anticon-close {
  color: black;
}
.ant-upload-wrapper{
  display: flex;
  flex-direction: column;
  gap:15px;
}



.ant-mention-dropdown {
  z-index: 9999 !important;  
}
