.signUp-box{
    >h1{
        font-size: 36px;
        line-height: 40px;
        font-weight: bold;
    }
    position: absolute;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    top: 8vh;
    left: 30vw;
    width: 40vw;
    height: 92vh;
    background-color:#ffff ;
    border: 2px solid rgba(172, 201, 255, 1);
    box-shadow: 0px 0px 10px 3px rgba(126, 193, 255, 0.5);
border-radius: 10px;
@media only screen and (max-width: 991px) {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
   }
}