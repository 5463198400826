.custom-input {
  padding: 10px 5px;
  border-radius: 4px;
  border: 1px solid #d1d1d1;
  outline: none;
  color: rgba(0, 0, 0, 0.6);
  @media only screen and (max-width: 991px) {
    font-size: 13px;
    padding: 5px 5px;
  }
  &__wrapper{
    padding-left: 10px;
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.304);
    @media only screen and (max-width: 991px) {
      font-size: 13px;
    }
  }
  &.readonly {
    background-color: #d1d1d1;
    cursor: not-allowed;
  }

  &.has-error {
    border: 2px solid #dd0505;
  }

  &__label {
    font-weight: 600;
    font-size: 12px;
    display: flex;
    align-items: center;
    color: #000000;
    display: inline-block;
    margin-bottom: 10px;
    &__ticket {
      color: #ffff !important ;
    }
    &.required {
      position: relative;
      &::after {
        position: absolute;
        content: "*";
        margin-left: 6px;
        top: 1px;
        color: #dd0505;
        font-size: 1rem;
      }
    }
  }
}
.ticket {
  &__input {
    background-color: #d9d9d9 !important;
  }
  color: #ffff !important;
}

option {
  font-size: 18px;
  height: 1.3em !important;
  font-weight: 300;
  padding: 12px 12px;
}
select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("../../assets/images/arrow.svg");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}
