.document-category{
    >h1{
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        width: 100%;
        
    }
}
.Admin .ant-tabs-tab  {
    color: black;
  }
  .Admin .ant-tabs-tab-active {
    color: black;
    background-color: #d1d1d1 !important;
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
    @media only screen and (max-width: 991px) {
      border-bottom-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }
  .ant-tabs .ant-tabs-tab {
    @media only screen and (max-width: 991px) {
      margin: 0 0 0 0;
      padding: 0 15px;
      max-height: 60px;
      width: 40px;
      height: 40px;
      border-radius: 8px;
      justify-content: center;
    }
  }
  .ant-tabs .ant-tabs-tab + .ant-tabs-tab {
    @media only screen and (max-width: 991px) {
      margin: 0 0 0 0;
    }
  }
  
  .ant-tabs .ant-tabs-tab:hover {
    color: black;
    background-color: white;
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
    @media only screen and (max-width: 991px) {
      border-radius: 8px;
    }
  }
  :where(.css-dev-only-do-not-override-1ez6nz9).ant-tabs
    .ant-tabs-content-holder {
    min-height: 100vh;
    @media only screen and (max-width: 991px) {
      max-width: 100vw;
    }
  }
  .ant-tabs-top > .ant-tabs-nav {
    @media only screen and (max-width: 991px) {
      margin-bottom: 0;
    }
  }
  :where(.css-dev-only-do-not-override-1ez6nz9).ant-tabs-left
    > .ant-tabs-content-holder
    > .ant-tabs-content
    > .ant-tabs-tabpane,
  :where(.css-dev-only-do-not-override-1ez6nz9).ant-tabs-left
    > div
    > .ant-tabs-content-holder
    > .ant-tabs-content
    > .ant-tabs-tabpane {
    padding-left: 0 !important;
  }
  
  .dashboardInfo {
    width: 25%;
    height: 80px;
    background: linear-gradient(180deg, #676df2 0%, #226bda 100%);
    color: white;
    font-size: 20px;
    font-weight: 700;
    box-shadow: 0px 4px 10px 0px rgba(33, 37, 53, 0.4);
    padding: 10px;
    text-align: center;
  }
  .anticon-close{
      color: white;
  }
  .nav-mobile-btn{
      margin-top: 10px;
      width: 40px;
      height: 40px;
      border: none;
      background-color: #2A2A2A;
      color: white;
  }
  .nav-mobile-btn-active{
      margin-top: 10px;
      width: 40px;
      height: 40px;
      border: none;
      background-color: white;
      border-radius: 8px;
      color: #2A2A2A;
  }
  :where(.css-dev-only-do-not-override-1ez6nz9).ant-drawer-left>.ant-drawer-content-wrapper {
      box-shadow: none;
  }
  :where(.css-1ez6nz9).ant-tabs-left >.ant-tabs-content-holder >.ant-tabs-content>.ant-tabs-tabpane, :where(.css-1ez6nz9).ant-tabs-left >div>.ant-tabs-content-holder >.ant-tabs-content>.ant-tabs-tabpane {
      padding-left: 0px !important;
  }
  