.services1 {
  margin-top: 3rem;
  @media only screen and (max-width: 991px) {
    margin-top: 0.2rem;
    margin-left: 5%;
  }
  > button {
    border: none;
    background-color: transparent;
    color: black;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    padding-bottom: 10px;
    @media only screen and (max-width: 991px) {
      font-size: 14px;
      padding-bottom: 5px;
    }
  }
  > button:hover {
    border-bottom: 1.7px solid rgba(46, 108, 223, 1);
  }
  .active {
    font-weight: bold;
    border-bottom: 1.7px solid rgba(46, 108, 223, 1);
  }
}
.servic-card {
  align-self: center;
  height: 400px;
  background-color: #ffff;
  box-shadow: 0px 4px 10px 0px rgba(225, 225, 225, 0.4);
  border-radius: 10px;
  @media only screen and (max-width: 991px) {
    height: 350px;
  }
  h2 {
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    @media only screen and (max-width: 991px) {
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
    }
  }
  h4 {
    color: rgba(0, 0, 0, 0.4);
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
    @media only screen and (max-width: 991px) {
      font-size: 11px;
      font-weight: 600;
      line-height: 16px;
    }
  }
  h3 {
    color: rgba(158, 158, 158, 1);
    font-size: 14px;
    font-weight: 400;
    text-align: left;
  }
}
.react-multi-carousel-list {
  max-width: 1900px;
  height: 480px;
  position: unset !important;
  margin: 0 2rem;
}
.react-multiple-carousel__arrow--right {
  right: calc(-5px) !important;
  @media only screen and (max-width: 991px) {
    right: calc(2% + 1px) !important;
  }

  @media only screen and (min-width: 2550px) {
    right: calc(4% + 1px) !important;
  }
}
.react-multiple-carousel__arrow--left {
  left: calc(-5px) !important;
  @media only screen and (max-width: 991px) {
    left: calc(2% + 1px) !important;
  }
  @media only screen and (min-width: 1900px) {
    left: calc(-30px) !important;
  }
}
.react-multi-carousel-dot-list {
  position: relative !important;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: transparent !important;
  color: white !important;
}
.bar {
  background: rgba(240, 240, 240, 0.53);
  border: 1px solid rgba(240, 240, 240, 0.53);
  border-radius: 30px;
  width: 100%;
  height: 12px;
  .complate {
    height: 12px;
    width: 57%;
    border-radius: 30px;
    background: rgba(24, 50, 78, 0.4);
  }
}
.up_doc {
  width: 100% !important;
  min-width: max-content !important;
  max-width: none !important;
}
.service-page {
  > h2 {
    font-size: 30px;
    font-weight: 700;
  }
  > h3 {
    color: rgba(0, 0, 0, 0.4);
    font-size: 16px;
    font-weight: 700;
  }
  > h4 {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
  }
  > p {
    margin-top: 15px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
  }
}
.v-line {
  height: 49px;
  width: 1px;
  background-color: rgba(158, 158, 158, 0.53);
  @media only screen and (max-width: 991px) {
    height: 76px;
  }
}
.reply-box {
  position: absolute;
  left: 66%;
  z-index: 4;
  border-radius: 6px;
  border: 1px solid var(--light-grey, #d1d1d1);
  background: #fff;
  @media only screen and (max-width: 991px) {
    left: 30%;
  }
  h3 {
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
  }
  h4 {
    color: #2e6cdf;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 123.077% */
  }
  p {
    padding-left: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    color: #4c4c4c;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
  }
  h5 {
    color: #9e9e9e;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  span {
    cursor: pointer;
    color: #000;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 145.455% */
  }
}
.desc-box {
  box-shadow: 0px 0px 10px rgba(225, 225, 225, 0.7);
  border-radius: 2px;
  padding: 15px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  margin: -8px 0 15px 1.5rem;
  width: 87%;
  @media only screen and (max-width: 991px) {
    margin: -5px 5px 0px 0px;
    border-radius: 10px;
  }
}
.step-box {
  box-shadow: 0px 0px 10px 0px rgba(225, 225, 225, 0.7);
  border-radius: 10px;

  margin: 8px 35px 8px 10px;
  align-items: center;
  padding-left: 15px;
  .title {
    color: rgba(24, 50, 78, 1);
    font-weight: 700;
    font-size: 15px;
    padding-right: 15px;
    overflow: hidden;
    white-space: nowrap;
  }
  .reply {
    cursor: pointer;
    margin-top: 13px;
    color: #2e6cdf;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
.title {
  color: rgba(24, 50, 78, 1);
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  @media only screen and (max-width: 991px) {
    display: inline-block;
    line-height: 20px;
  }
}
.react-multi-carousel-dot--active button {
  background: rgba(46, 108, 223, 1) !important;
}
.react-multi-carousel-dot button {
  border-color: transparent !important;
  background: rgba(217, 217, 217, 1);
}
.ant-btn-primary.ant-btn-background-ghost {
  border: 1.5px solid;
}
.my-carousel {
  @media only screen and (min-width: 1900px) {
    width: 100%;
  }
}
.react-multi-carousel-list {
  @media only screen and (max-width: 991px) {
    height: 380px;
    width: 225px;
    margin: 2% 11%;
  }
  @media only screen and (min-width: 1900px) {
    height: 500px;
  }
}

.service-des {
  @media only screen and (min-width: 1350px) {
    width: 70% !important;
  }
  @media only screen and (min-width: 1900px) {
    width: 80% !important;
  }
  @media only screen and (min-width: 2800px) {
    width: 85% !important;
  }
}
.user-box {
  border: 2px solid rgba(46, 108, 223, 1);
  background-color: rgba(46, 108, 223, 0.05);
  border-radius: 20px;
  min-height: 100px;
  max-height: max-content;
  width: 85%;
  > div > h3 {
    font-weight: 600;
    font-size: 16px;
  }
  > div > div > h3 {
    font-weight: 500;
    font-size: 14px;
  }
  > div > h4 {
    font-weight: 500;
    font-size: 14px;
    margin-left: 10px;
  }
  > div > h2 {
    font-weight: 600;
    font-size: 16px;
    color: #bc2323;
    text-align: center;
    width: 100%;
  }
}
.service-btn {
  @media only screen and (min-width: 1300px) {
    padding-right: 30px;
  }
  @media only screen and (min-width: 1900px) {
    padding-left: 80px;
    padding-right: 40px;
    direction: rtl;
    > div {
      width: 60% !important;
    }
  }
}
.fixedButton {
  position: absolute;
  bottom: 25px;
  left: 0;
  justify-content: center;
}
.step-button {
  text-align: center;
  padding: 8px 10px 8px 10px;
  border-radius: 5px;
  margin: 1px 1px 1px 1px;
  background-color: white;
  border: none;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  position: relative;
  align-items: center;
  display: flex;

  color: rgba(24, 50, 78, 1);
  @media only screen and (max-width: 991px) {
    padding: 8px 5px;
    font-size: 11px;
  }
  .desible {
    color: rgba(24, 50, 78, 0.3) !important;
  }
}
.step-button-active {
  text-align: center;
  padding: 15px 10px 15px 10px;
  border-radius: 5px;
  margin: 1px 1px 1px 1px;
  background-color: white;
  border: none;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  position: relative;

  color: rgba(24, 50, 78, 1);
  @media only screen and (max-width: 991px) {
    padding: 8px 5px;
    font-size: 11px;
  }
  .desible {
    color: rgba(24, 50, 78, 0.3) !important;
  }

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 10%;
    height: 0;
    border-top: solid 10px var(--triangle-color);
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
  }
}

:root {
  --send-bg: #e0ffee;
  --send-color: black;
  --receive-bg: #e3f3fb;
  --receive-text: black;
  --page-background: white;
}

.body {
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  font-size: 14px;
  font-weight: normal;
  display: flex;
  flex-direction: column;
  background-color: var(--page-background);
  p {
    // width: 250px;
    max-width: 500px;
    word-wrap: break-word;
    margin-bottom: 8px;
    line-height: 16px;
    position: relative;
    padding: 10px 20px;
    border-radius: 25px;
  }
  span {
    font-size: 10px;
    display: block;
    text-align: end;
  }
}

.send {
  color: var(--send-color);
  background: linear-gradient(to right, #bbfeda, #e0ffee);
  align-self: flex-end;
  @media only screen and (max-width: 991px) {
    font-size: 11px;
  }
  &:before {
    right: -7px;
    width: 20px;
    background-color: var(--send-bg);
    border-bottom-left-radius: 16px 14px;
  }

  &:after {
    right: -26px;
    width: 26px;
    background-color: var(--page-background);
    border-bottom-left-radius: 10px;
  }
  &:hover {
    background: linear-gradient(to right, #a9f6cd, #c4f8db);
  }
}
.receive {
  background: linear-gradient(to right, #e3f3fb, #bbe7fe);
  color: black;
  align-self: flex-start;
  @media only screen and (max-width: 991px) {
    font-size: 11px;
  }
  &:before {
    left: -7px;
    width: 20px;
    background-color: var(--receive-bg);
    border-bottom-right-radius: 16px 14px;
  }

  &:after {
    left: -26px;
    width: 26px;
    background-color: var(--page-background);
    border-bottom-right-radius: 10px;
  }
}
.message-box {
  flex: 0 1 40px;
  width: 100%;
  position: relative;
  border-radius: 20px;
  margin-top: 50px;
  z-index: 1;
  @media only screen and (max-width: 991px) {
    margin: 5px;
    padding: 8px 0 0 8px;
  }

  & .message-input {
    // border: none;
    // outline: none !important;
    // resize: none;
    // font-size: 14px;
    // margin: 0;
    // width: 100%;
    // overflow: scroll;
    background: inherit;
    @media only screen and (max-width: 991px) {
      font-size: 12px;
    }
  }

  textarea:focus:-webkit-placeholder {
    color: transparent;
  }

  & .message-submit {
    position: absolute;
    top: 0px;
    right: 5px;
    color: #fff;
    border: none;
    background: inherit;
    font-size: 10px;
    text-transform: uppercase;
    padding: 5px 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
  }
}

.change_status
  > .ant-select-single.ant-select-sm:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: inherit !important;
  border: none;
  color: white;
}

.ant-btn-default:disabled {
  color: rgba(24, 50, 78, 0.3) !important;
  background-color: white !important;
}
.ant-modal-close-icon {
  color: black !important;
}

.step-button-active {
  text-align: center;
  padding: 8px 10px 8px 10px;
  border-radius: 5px;
  margin: 1px 1px 1px 1px;
  background-color: white;
  border: none;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  position: relative;
  align-items: center;
  display: flex;

  color: rgba(24, 50, 78, 1);
  @media only screen and (max-width: 991px) {
    padding: 8px 5px;
    font-size: 11px;
  }
  .desible {
    color: rgba(24, 50, 78, 0.3) !important;
  }

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 10%;
    height: 0;
    border-top: solid 10px var(--triangle-color);
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
  }
}

.templateButton {
  color: #2e6cdf;
  border: solid #2e6cdf 1px;
  font-weight: 700;
  border-radius: 20px;
  background-color: #f4f6fa;
  margin-right: 10px;
}
