.service{
    h1{
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        
      }
}
.condition{
  .h-line{
    width: 100%;
     background-color:  rgba(225, 225, 225, 1);
     height: 2px;
     margin-bottom: 5px;
  }
  >h2{
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    
  }
  
  h3{
font-size: 14px;
font-weight: 700;
line-height: 22px;
text-align: left;

  }
}
.add-service{
  >svg{
    @media only screen and (max-width: 991px) {
      margin-top: 20%;
    }
  }
    >h2{
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        
      }
      
      h3{
font-size: 18px;
font-weight: 700;
line-height: 22px;
text-align: left;

      }
      .h-line{
        width: 100%;
         background-color:  rgba(225, 225, 225, 1);
         height: 2px;
         margin-bottom: 15px;
      }
}
.service-view-step{
  >h4{
    font-size: 16px;
    font-weight: 700;
  }
}
.h-line{
  width: 100%;
   background-color:  rgba(225, 225, 225, 1);
   height: 2px;
   margin-bottom: 10px;
}
.ant-checkbox-wrapper+.ant-checkbox-wrapper{
    margin-inline-start: 0px !important;
}
.DYXVr{
  width: 150px ;
  min-width:max-content !important;
}
.delete-icon:hover {
  fill: #BC2323;
}
.service-view{
  
    @media only screen and (max-width: 991px) {
      margin-top: 20%;
    }
  
}
