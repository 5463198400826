.table-box{
    width: 90%;
    max-width: 1600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;

}
.top-tabs{
  .ant-tabs-tab{
    color: black;
    background-color: white;
  }
  .ant-tabs-tab .ant-tabs-tab-active{
    color: black;
    background-color: rgba(209, 209, 209, 1);
  }
}
.Modal1 {
  width: 50vw;
  height: 80vh;
  background: #ffffff;
  position: absolute;
  top: 10vh;
  left: 25vw;
  @media only screen and (max-width: 991px) {
    width: 70vw;
    left: 15vw;
  }
  @media only screen and (max-width: 400px) {
    width: 90vw;
    left: 5vw;
  }
  overflow-y: scroll;
  border-radius: 4px;
  padding-right: 2rem !important;
  padding-left: 2rem !important ;
  @media only screen and (max-width: 991px) {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  
  > div > div > div > img {
    width: 45px !important;
    height: 45px;
  }
  > div > div > label {
    position: relative;
    &::after {
      position: absolute;
      content: "*";
      margin-left: 5px;
      top: 1px;
      color: #dd0505;
      font-size: 1rem;
    }
  }

  &_line {
    border: none;
    border-bottom: 1px solid #d1d1d1;
    width: 80%;
    margin-top: -30px;
  }

  > div > h2 {
    font-size: 28px;
    line-height: 45px;
    padding-bottom: 1rem;
    font-weight: 600;
    line-height: 45px;
    text-align: center;
    @media only screen and (max-width: 991px) {
      font-size: 20px;
      line-height: 40px;
    }
    @media only screen and (max-width: 400px) {
      font-size: 25px;
      line-height: 30px;
    }
  }
  > div > h3 {
    font-size: 30px;
    font-weight: 700;
    line-height: 35px;
    text-align: center;
    @media only screen and (max-width: 991px) {
      font-size: 25px;
      line-height: 30px;
    }
  }
}
.otp{
  table{
    width: 100%;
    th{
      background-color: rgba(247, 247, 247, 1);
      text-align: center;
      padding: 25px;
      font-weight: 700;
      font-size: 18px;
    }
    td{
      background-color: #FCFCFC;
      border-bottom: 1px solid rgba(242, 242, 242, 1);
      padding: 20px;
      text-align: center;
      font-size: 16px;
    }
}}
.name-table:hover{
text-decoration: underline;
cursor: pointer;
}
.serch-box{
    width: 55%;
    margin-left: 5%;
    margin-top:70px;
    @media only screen and (max-width: 991px) {
     margin-left: 3%;
     width: 70%;
     margin-top: 30px;
    }
}
:where(.css-dev-only-do-not-override-1ez6nz9).ant-btn-default:not(:disabled):hover {
  color: #A1813A;
}