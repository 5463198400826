.chang-box{
    >h1{
        font-size: 40px;
        line-height: 44px;
        font-weight: bold;
    }
    position: absolute;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    top: 10vh;
    left: 30vw;
    width: 40vw;
    height: 63vh;
    background-color:#ffff ;
    border: 2px solid rgba(172, 201, 255, 1);
    box-shadow: 0px 0px 10px 3px rgba(126, 193, 255, 0.5);
border-radius: 10px;
}