.wrapp{
background-color: white;
min-width: 80vw;
min-height: 100vh;
}
.Events_wrapp{
    padding: 40px 0px 0px  40px;
}
.Select_link{
    color: #2e6cdf;
    font-weight: bold;
    font-size: 14px;
    padding: 0px 12px;
    cursor: pointer;
    
    &:hover{
        color: #1b5dd8;
        transition: 1s;
    }
}

// .table_wrapp{
//     padding: 0px 55px;

// }

.form_btn{
    display: flex;
    justify-content: center;
}

.form_header{
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-size: 35px;
    padding: 15px;
}
