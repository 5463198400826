.auth-box{
    >h1{
        font-size: 40px;
        line-height: 44px;
        font-weight: bold;
        margin-bottom: -10px;
    }
    >h2{
        font-size: 20px;
        line-height: 24px;
        font-weight: 400;
    }
     div>div > h3 {
        font-size: 18px;
        font-weight: 400;
        line-height: 25px;
        text-align: center;
        @media only screen and (max-width: 991px) {
          font-size: 14px;
          line-height: 16px;
        }
      }
    position: absolute;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap:25px;
    display: flex;
    flex-direction: column;
    top: 10vh;
    left: 30vw;
    width: 40vw;
    height: 60vh;
    background-color:#ffff ;
    border: 2px solid rgba(172, 201, 255, 1);
    box-shadow: 0px 0px 10px 3px rgba(126, 193, 255, 0.5);
border-radius: 10px;
@media only screen and (max-width: 991px) {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
 }
}

.disable {
    color: gray;
  }
  .enable {
    color: rgba(46, 108, 223, 1);
    cursor: pointer;
  }