.detile {
  align-items: center;
  padding-left: 3rem;
  padding-right: 3rem;
  @media only screen and (max-width: 991px) {
    padding-left: 0;
    padding-right: 0;
  }
  > div {
    width: 100%;
    justify-content: center;
    display: flex;
    > table {
      width: 100%;
      th {
        background-color: rgba(247, 247, 247, 1);
        text-align: center;
        padding: 10px;
        font-weight: 700;
      }
      td {
        background-color: #fcfcfc;
        border-bottom: 1px solid rgba(242, 242, 242, 1);
        padding: 10px;
      }
    }
  }
}
.personalTable {
  td {
    font-weight: 700;

    > h6 {
      font-weight: 400;
    }
  }
}
.edit-icon {
  position: absolute;
  left: 57%;
  cursor: pointer;

}
.edit-icon-mobile{
  position: absolute;
  right: 7%;
  cursor: pointer;
  width: 8px;
  height: 8px;
}

.forms {
  > h1 {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
  }
  > div > h2 {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
  }
  .line {
    width: 100%;
    height: 1.5px;
    background-color: rgba(209, 209, 209, 1);
    margin-bottom: 40px;
    @media only screen and (max-width: 750px) {
      margin-bottom: 5px !important;
    }
  }
}
.form-card {
  width: 98%;
  height: 80px;
  text-align: center;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  align-items: center;
  background-color: #ffff;
  box-shadow: 0px 0px 10px rgba(225, 225, 225, 0.7);
 

  &__title {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
    padding-left: 5px;
    padding-right: 4px;
    @media only screen and (max-width: 991px) {
      width: 40%;
      padding-left: 10px;
      max-height: 60px;
    }
    > h5 {
      font-size: 16px;
      font-weight: bold;
      line-height: 22px;
      @media only screen and (max-width: 750px) {
        font-size: 12px;
        line-height: 18px;
      }
    }
    > h6 {
      font-size: 14px;
      font-weight: bold;
      line-height: 20px;
      color: #18324e;
      @media only screen and (max-width: 750px) {
        font-size: 11px;
        line-height: 14px;
      }
    }
  }
  &__divider {
    height: 90%;
    width: 1px;
    margin-left: 3px;
    background-color: rgba(225, 225, 225, 1);
    
  }
  &__desc {
    display: flex;
    flex-direction: column;
    width: 40%;
    padding-left: 2%;
    padding-right: 1%;
    font-size: 16;
    line-height: 20px;
    @media only screen and (max-width: 991px) {
      padding-left: 6%;
      font-size: 12px;
      @media only screen and (max-width: 550px) {
        padding-left: 4%;
        font-size: 10px;
      }
    }
  }
  & > a > button {
    border: none;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    background-color: rgba(24, 50, 78, 1);
    color: #ffff;
    font-size: 16px;
    text-align: center !important;
    margin-right: 2%;
    margin-left: 1%;
    @media only screen and (max-width: 750px) {
      font-size: 10px;
    }
  }
  > a {
    color: #ffff;
    text-decoration: none;
    width: 20%;
    height: 40%;
  }
  &__button {
    border: none;
    border-radius: 4px;
    width: 11%;
    height: 20%;
    background-color: rgba(24, 50, 78, 1);
    color: #ffff;
    font-size: 16px;
    text-align: center;
    margin-right: 2%;
    margin-left: 1%;
    @media only screen and (max-width: 750px) {
      font-size: 10px;
    }
  }
}
.accordion {
  width: 95%;
}

.accordion__button {
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  align-items: center;
  text-align: center !important;
  box-shadow: 0px 0px 10px rgba(225, 225, 225, 0.7);
  background-color: #ffff !important;
  @media only screen and (max-width: 991px) {
    height: 60px;
  }
}
.accordion__panel {
  height: 220px;
  overflow-y: scroll;
  align-items: center;
  justify-content: center;
  background: #f3f5f8;
  box-shadow: 0px 0px 10px rgba(225, 225, 225, 0.7);
}
.accordion__button:before {
  display: inline-block;
  content: url("./expand.svg") !important;
  margin-right: 32px;
  margin-bottom: 10px;
  border-bottom: none !important;
  border-right: none !important;
  transform: none !important;
  @media only screen and (max-width: 991px) {
    content: url("./expand.svg") !important;
  }
}
.accordion__button[aria-expanded="true"]::before,
.accordion__button[aria-selected="true"]::before {
  transform: none !important;
  content: url("./notExpand.svg") !important;
}
.user-box {
  border: 2px solid rgba(46, 108, 223, 1);
  background-color: rgba(46, 108, 223, 0.05);
  border-radius: 20px;
  min-height: 350px;
  width: 85%;
  > div > h3 {
    font-weight: 600;
    font-size: 16px;
  }
  > div >div> h3 {
    font-weight: 500;
    font-size: 14px;
  }
  > div > h4 {
    font-weight: 500;
    font-size: 14px;
    margin-left: 10px;
  }
  >div> h2 {
    font-weight: 600;
    font-size: 16px;
    color: #bc2323;
    text-align: center;
    width: 100%;
  }
}
.ant-table-wrapper .ant-table-row-indent+.ant-table-row-expand-icon{
  @media only screen and (max-width: 991px) {
    margin-top: -5px;
  }
}
