.subject-input {
  border-left: none !important;
  border-right: none !important;
  border-top: 1px solid #d9d9d9;
  border-bottom: none !important;
  border-radius: 0;
  box-shadow: none;

  &:hover {
    border-top: 1px solid #40a9ff;
    border-bottom: 1px solid #40a9ff;
  }

  &:focus {
    border-top: 1px solid #40a9ff;
    border-bottom: 1px solid #40a9ff;
    outline: none;
    box-shadow: none;
  }
}
